<template lang="pug">
    .header(:class="{'header_transparent': isOnBoardingPage}")
        ._group
            router-link._logo-box(:to="logoLink" tag="a" v-if="!isRepeatOnboarding")
                glitch-animation
                svg-icon(icon-name="logo")._logo-icon
            ._back-btn(v-else @click="cancelSecondOnBoardingFromMixin")
                svg-icon(icon-name="chevron-left")._back-icon
                | {{ locale("back-to-profile") }}
            search._search(v-if="interfaceAvailable && !isOnBoardingPage && haveRealPhotos")
            ._notifications(v-if="pageName !== 'notifications' && interfaceAvailable && isDesktopView && !isOnBoardingPage && haveRealPhotos")
                notifications-module._notifications-module

        ._group(v-if="pageName !== 'Login' && !isOnBoardingPage")
            lang-switcher._lang-switcher(v-if="isDesktopView")
            subscriptions-widget(v-if="interfaceAvailable && isDesktopView && haveRealPhotos" key="widget")
            user-dropdown(v-if="interfaceAvailable && isDesktopView")
            ui-button(v-if="!isUserAuth" :text="locale('createAccount')" size="small" key="btn" @click.native="$router.push('/welcome/login')")._create-account
            router-link._message(to="/settings" tag="button" v-if="isMobileView && interfaceAvailable && pageName !== 'settings'")
                svg-icon._message-icon(icon-name="settings")

        ._group.-last.-onboarding(v-if="isOnBoardingPage || pageName === 'Login'")
            a(:href="SUPPORT_LINK")._support
                svg-icon(icon-name="support")._support-icon
                ._support-text {{ locale("support") }}
</template>

<script>
import svgIcon from "../icon-base/icon-base";
import search from "../search/search";
import NotificationsModule from "../notifications-module/notifications-module";
import UiButton from "../../ui/ui-button/ui-button";
import SubscriptionsWidget from "@/components/subscriptions/components/subscriptions-widget/subscriptions-widget";
import GlitchAnimation from "../../ui/glitch-animation/glitch-animation";
import LangSwitcher from "@/components/app/lang-switcher/lang-switcher.vue";
import UserDropdown from "../user-dropdown/user-dropdown.vue";

export default {
    name: "MainHeader",
    components: {
        UserDropdown,
        LangSwitcher,
        GlitchAnimation,
        SubscriptionsWidget,
        UiButton,
        NotificationsModule,
        svgIcon,
        search,
    },
    props: {
        authStep: {
            require: false,
            type: String,
            default: "",
        },
    },
    data: function () {
        return {
            menu: {
                active: false,
                animation: false,
                first: false,
                second: false,
            },
            sound: false,
            searchPlaceholder: "Поиск...",
            onBoardingPassed: 0,
        };
    },
    computed: {
        isRepeatOnboarding: function () {
            return (
                this.isOnBoardingPage &&
                this.userPassedOnBoardingTypesFromMixin.length > 1
            );
        },
        logoLink: function () {
            // if (this.myUser.display_name === "Newbie") {
            //     return "/welcome/name";
            // }
            // if (this.myPerson.public_url) {
            //     return `/${this.myPerson.public_url}`;
            // }
            // if (this.myPerson.uuid) {
            //     return `/person/${this.myPerson.uuid}`;
            // }

            return "/";
        },
        balance: {
            get() {
                return this.$store.getters["user/balance"];
            },
            set(value) {
                this.$store.dispatch("user/setNewBalance", value);
                return value;
            },
        },
        isOnBoardingPage: (state) => state.pageName.includes("Welcome-"),
    },
    methods: {
        showModal(name) {
            this.$modal.show(name, { props: { allowComponent: true } });
        },
        logout() {
            this.$store.dispatch("auth/signout");
            this.$router.push({ name: "Login" });
            this.$router.go();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "main-header";
</style>
