<template lang="pug">
    section.ai-better-deal-promo.section
        ._wrap.wrap
            h2._title {{ title }}
            ._content
              ._list
                     ._ai(v-for="(ai, index) in list" :key="index")
                        img(:src="require(`../../../assets/images/ai-better-deal/${ai.code}.png`)")._ai-logo
                        span._ai-info
                            span._ai-title {{ ai.title }}
                            span._ai-description {{ ai.description }}
              ._total
                    span._total-text Итого: $140
            ._footer
                    span._footer-text У нас вы получаете тоже самое в одном месте
                    span._footer-highlight выгоднее на 87%
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiBetterDealPromo",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false,
        },
    },
    data: () => {
        return {
            title : "Если оплачивать каждый сервис отдельно вы потратите:",
            list: [
                {
                    code: "chatgpt",
                    url: "chat-gpt",
                    title: "ChatGPT",
                    description: "$20",
                },
                {
                    code: "midjourney",
                    url: "pika",
                    title: "Midjourney",
                    description: "$30",
                },
                {
                    code: "dalle",
                    url: "stable-diffusion",
                    title: "Dalle-3",
                    description: "$20",
                },
                {
                    code: "pika",
                    url: "pika",
                    title: "Pika",
                    description: "$28",
                },
                {
                    code: "runway",
                    url: "midjourney",
                    title: "Runway",
                    description: "$12",
                },
                {
                    code: "faceswap",
                    url: "pika",
                    title: "FaceSwap",
                    description: "$20",
                },
                {
                    code: "yandex",
                    url: "face-swap",
                    title: "YandexGPT",
                    description: "$20",
                },
                {
                    code: "stable-diffusion",
                    url: "stable-diffusion",
                    title: "Stable Diffusion",
                    description: "$10",
                },
            ],
        };
    },
    computed: {
        analogList: (state) => state.$t("AILanding.howWork.analog.chatGPT"),
        separator: (state) => state.$t("AILanding.howWork.separator.chatGPT"),
        merlinList: (state) => state.$t("AILanding.howWork.merlin.chatGPT"),
        btn: (state) => state.$t("AILanding.howWork.btns.chatGPT"),
        filteredList: (state) => state.list.filter((a) => a.code !== state.ai),
    },
};
</script>

<style lang="scss" src="./ai-better-deal-promo.scss"></style>
