<template lang="pug">
    section.ai-how-work.section
        ._wrap.wrap
              h2._title
                span(v-if="ai !== 'main'" v-html="title")
                span(v-else) Почему Merlinface?
              ._list
                  ._box
                      img(src="../../../assets/images/ai-how-work/analog.png")._box-img
                      ._box-list
                          ._box-list-li(v-for="(text, index) in analogList" :key="index") 👎 {{ text }}
                  ._box.-separator
                    ._separator
                        ._separator-line
                        ._separator-icon-box
                            svg-icon(icon-name="connect" added-catalog="ai-how-work")._separator-icon
                        ._separator-line
                    ._separator-text {{ separator }}
                  ._box.-merlin
                    img(src="../../../assets/images/ai-how-work/merlinGPT.png")._merlin-logo
                    ._box-list
                        ._box-list-li(v-for="(text, index) in merlinList" :key="index") 🎉 {{ text }}
              ._footer
                router-link(to="/welcome/login")
                    ui-button(variant="rounded" :text="btn")._btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiHowWork",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false,
        },
    },
    computed: {
        title: (state) => state.$t("AILanding.howWork.titles.chatGPT"),
        analogList: (state) => state.$t("AILanding.howWork.analog.chatGPT"),
        separator: (state) => state.$t("AILanding.howWork.separator.chatGPT"),
        merlinList: (state) => state.$t("AILanding.howWork.merlin.chatGPT"),
        btn: (state) => state.$t("AILanding.howWork.btns.chatGPT"),
    },
};
</script>

<style lang="scss" src="./ai-how-work.scss"></style>
